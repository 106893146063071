import { alertActionTypes } from "./alerts.types"

const INITAL_STATE = {
    alerts : {
        type: null,
        message: null
    },
    error: null,
    visible: false,
}

const alertsReducer = (state = INITAL_STATE, action) =>  {
    switch(action.type){
        case alertActionTypes.SET_ALERT:{
            return {
                ...state,
                alerts: {...action.payload}
            }
        }
        case alertActionTypes.TOGGLE_VISIBLE: {
            return{
                ...state,
                visible : action.payload
            }
        }
        default:
            return state
    }
}

export default alertsReducer