import sprintsApi from "../../apis/sprints/sprints.api";
import {
  setAlertTypeAndMessage,
  toggleAlertVisibility,
} from "../alerts/alerts.actions";
import { clearAllUsers } from "../user/user.actions";
import { sprintActionTypes } from "./sprints.types";

export const addToAllSprints = (sprintObj) => ({
  type: sprintActionTypes.ADD_SPRINT,
  payload: sprintObj,
});

export const setHasError = (val) => ({
  type: sprintActionTypes.SET_HAS_ERRORS,
  payload: val,
});

export const setError = (val) => ({
  type: sprintActionTypes.SET_ERRORS,
  payload: val,
});

export const setSelectedSprintAction = (sprintObj) => ({
  type: sprintActionTypes.SET_SELECTED_SPRINT,
  payload: sprintObj,
});

export const setIssuesLoadingAction = (val) => ({
  type: sprintActionTypes.SET_ISSUES_LOADING,
  payload: val,
});

export const setSprintIssuesWTimeLoggedAction = (valObj) => ({
  type: sprintActionTypes.SET_ISSUES_W_TIME_LOGGED,
  payload: valObj,
});

export const clearIssuesForUser = (user) => ({
  type: sprintActionTypes.CLEAR_ISSUES_FOR_USER,
  payload: user,
});

export const clearAllIssuesForAllUser = () => ({
  type: sprintActionTypes.CLEAR_ALL_ISSUES_FOR_ALL_USER,
  payload: null,
});

export const setWorkLogLoading = (value) => ({
  type: sprintActionTypes.SET_WORKLOG_LOADING,
  payload: value
})

export const setWorkLogForGivenUser = (valObj) => ({
  type: sprintActionTypes.SET_WORKLOG,
  payload: valObj,
});

export const clearWorklogOfCurrentSprintForSelectedUser = (user) => ({
  type: sprintActionTypes.CLEAR_WORKLOG_FOR_SELECTED_USER,
  payload: user
});

export const clearAllWorklogForAllUser = () => ({
  type: sprintActionTypes.CLEAR_ALL_WORKLOG_FOR_ALL_USER,
  payload: null,
});

export const clearAllSprintIssuesForAllUser = () => {
  return async (dispatch , getState) => {
    dispatch(
      setAlertTypeAndMessage({
        type: "info",
        message: `All the issues and worklogs for every user has been cleared`,
      })
    );
    dispatch(toggleAlertVisibility(true));
    dispatch(clearAllIssuesForAllUser());
    dispatch(clearAllWorklogForAllUser())
    dispatch(clearAllUsers())
    if(!getState().user.selectedUser || !getState().sprints.selectedSprint) {
      dispatch(
        setAlertTypeAndMessage({
          type: "error",
          message: "No user or sprint selected"
        })
      );
      dispatch(toggleAlertVisibility(true));
      return
    }
    const promises = [dispatch(fetchSprintIssuesByUsernameAction()), dispatch(fetchWorkLogDayWise())]
    Promise.allSettled(promises);
  };
};

export const clearSprintIssuesForUser = () => {
  return async (dispatch, getState) => {
    const { selectedUser } = getState().user;
    const { selectedSprint } = getState().sprints;
    dispatch(
      setAlertTypeAndMessage({
        type: "info",
        message: `Issues & Worklogs of sprint  ${selectedSprint.name} cleared for ${selectedUser.displayName}`,
      })
    );
    dispatch(toggleAlertVisibility(true));
    dispatch(clearIssuesForUser(selectedUser));
    dispatch(clearWorklogOfCurrentSprintForSelectedUser(selectedUser))
    if(!getState().user.selectedUser || !getState().sprints.selectedSprint) {
      dispatch(
        setAlertTypeAndMessage({
          type: "error",
          message: "No user or sprint selected"
        })
      );
      dispatch(toggleAlertVisibility(true));
      return
    }
    const promises = [dispatch(fetchSprintIssuesByUsernameAction()), dispatch(fetchWorkLogDayWise())]
    Promise.allSettled(promises);
  };
};

export const fetchSprintDetails = (sprintName) => {
  return async (dispatch) => {
    try {
      dispatch(
        setAlertTypeAndMessage({
          type: "info",
          message: "Please wait while we fetch sprint details",
        })
      );
      dispatch(toggleAlertVisibility(true));
      dispatch(setHasError(true));

      await sprintsApi.get(`/${sprintName.trim()}`).then((res) => {
        dispatch(addToAllSprints(res.data.data));
        dispatch(setHasError(false));
        dispatch(
          setAlertTypeAndMessage({
            type: "success",
            message: res.data.message,
          })
        );
        dispatch(toggleAlertVisibility(true));
      });
    } catch (error) {
      dispatch(setError("error " + error.response?.data.message));
      dispatch(
        setAlertTypeAndMessage({
          type: "error",
          message: "message " + error.response?.data.message,
        })
      );
      dispatch(toggleAlertVisibility(true));
    }
  };
};

export const fetchActiveSprintsAction = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(
        setAlertTypeAndMessage({
          type: "info",
          message: "Please wait while we fetch ACTIVE sprint",
        })
      );
      dispatch(toggleAlertVisibility(true));

      await sprintsApi.get("/activesprints").then((res) => {
        const activeSprintExists = getState().sprints.allSprints.filter(
          (sprint) => {
            return sprint.name === res.data.data.name;
          }
        );

        if (activeSprintExists.length > 0) {
          dispatch(
            setAlertTypeAndMessage({
              type: "info",
              message: `ACTIVE sprint ${activeSprintExists[0].name} already exists in the selection`,
            })
          );
          dispatch(toggleAlertVisibility(true));
          return;
        }
        dispatch(setSelectedSprintAction(res.data.data));
        dispatch(addToAllSprints(res.data.data));
        dispatch(
          setAlertTypeAndMessage({
            type: "success",
            message: res.data.message,
          })
        );
        dispatch(toggleAlertVisibility(true));
      });
    } catch (error) {
      dispatch(setError("error " + error.response?.data.message));
      dispatch(
        setAlertTypeAndMessage({
          type: "error",
          message:
            "error " + (error.response ? error.response.data.message : error),
        })
      );
      dispatch(toggleAlertVisibility(true));
    }
  };
};

export const fetchSprintIssuesByUsernameAction = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedSprint, sprintIssuesWTimeLogged } = getState().sprints;
      const { selectedUser } = getState().user;

      if(!getState().user.selectedUser || !getState().sprints.selectedSprint) {
        dispatch(
          setAlertTypeAndMessage({
            type: "error",
            message: "No user or sprint selected"
          })
        );
        dispatch(toggleAlertVisibility(true));
        return
      }

      if (
        sprintIssuesWTimeLogged &&
        sprintIssuesWTimeLogged[selectedUser.id] !== undefined &&
        sprintIssuesWTimeLogged[selectedUser.id][selectedSprint.name] !==
          undefined &&
        sprintIssuesWTimeLogged[selectedUser.id][selectedSprint.name].length > 0
      ) {
        dispatch(setIssuesLoadingAction(false));
        return sprintIssuesWTimeLogged;
      }

      await sprintsApi
        .get(
          `/issues/${selectedUser.displayName}/${selectedSprint.id}?userKey=${selectedUser.id}`
        )
        .then((res) => {
          dispatch(
            setAlertTypeAndMessage({
              type: "success",
              message: res.data.message,
            })
          );
          dispatch(toggleAlertVisibility(true));
          dispatch(
            setSprintIssuesWTimeLoggedAction({
              data: res.data.data,
              user: selectedUser,
            })
          );
          dispatch(setIssuesLoadingAction(false));
        });
    } catch (error) {
      console.log(error);
      dispatch(
        setAlertTypeAndMessage({
          type: "error",
          message: "error " + error.response?.data.error,
        })
      );
      dispatch(toggleAlertVisibility(true));
    }
  };
};

export const fetchWorkLogDayWise = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedSprint, worklog } = getState().sprints;
      const { selectedUser } = getState().user;

      if(!getState().user.selectedUser || !getState().sprints.selectedSprint) {
        dispatch(
          setAlertTypeAndMessage({
            type: "error",
            message: "No user or sprint selected"
          })
        );
        dispatch(toggleAlertVisibility(true));
        return
      }

      if (
        worklog &&
        worklog[selectedUser.id] !== undefined &&
        worklog[selectedUser.id][selectedSprint.name] !== undefined &&
        worklog[selectedUser.id][selectedSprint.name].length > 0
      ) {
        dispatch(setWorkLogLoading(false));
        return worklog;
      }

      await sprintsApi.get(`/worklog/dayWise/${selectedUser.displayName}/${selectedSprint.id}?userKey=${selectedUser.id}`)
      .then((res) => {
        dispatch(
          setAlertTypeAndMessage({
            type: "success",
            message: res.data.message,
          })
        );
        dispatch(toggleAlertVisibility(true));
        dispatch(
          setWorkLogForGivenUser({
            data: res.data.data,
            user: selectedUser,
          })
        );
        dispatch(setWorkLogLoading(false));
      })
    } catch (error) {
      dispatch(
        setAlertTypeAndMessage({
          type: "error",
          message: "error " + error.response?.data.error,
        })
      );
      dispatch(toggleAlertVisibility(true));
    }
  };
};
