import React, { useEffect, useState } from "react";
import IssuesTable from "../issues table/issues-table.component";
import { FaTrash } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  getIssuesLoading,
  getSprintIssuesWTimeLogged,
  getWorkLogByUser,
  getWorkLogLoading,
  selectSelectedSprint,
} from "../../redux/sprints/sprints.selectors";
import { selectedUser } from "../../redux/user/user.selectors";
import {
  clearAllSprintIssuesForAllUser,
  clearSprintIssuesForUser,
  fetchSprintIssuesByUsernameAction,
  fetchWorkLogDayWise,
  setIssuesLoadingAction,
  setWorkLogLoading,
} from "../../redux/sprints/sprints.actions";
import Popup from "../alerts/popup.component";
import WorklogTableComponent from "../issues table/worklogTable.component";

const SprintsTable = ({
  selectedSprint,
  selectedUser,
  sprintIssuesWTimeLogged,
  issuesLoading,
  setIssuesLoading,
  fetchSprintIssues,
  clearSprintIssuesForUser,
  clearAllIssues,
  fetchWorklog,
  workLog,
  worklogLoading,
  setWorkLogLoadingAction,
}) => {
  const [showTable, setShowTable] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showWorkLog, setShowWorkLog] = useState(false);

  useEffect(() => {
    setIssuesLoading(true);
    setWorkLogLoadingAction(true);
    fetchSprintIssues();
    fetchWorklog();
  }, [selectedUser, selectedSprint]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      className="container-fluid mt-2"
    >
      {selectedSprint && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="btn-group d-grid d-md-flex justify-content-md-end">
                <div
                  type="button"
                  className="btn btn-success col-lg-9 col-md-10 mb-1"
                  onClick={() =>
                    selectedUser && clearSprintIssuesForUser(selectedUser)
                  }
                  title="Click to refresh issues"
                >
                  {selectedSprint.name}
                </div>
                <div
                  type="button"
                  className="btn btn-success col-lg-1 col-md-2 mb-1"
                  onClick={() => selectedUser && setShowPopUp(true)}
                >
                  <FaTrash />
                </div>
                <div
                  type="button"
                  title="worklog"
                  onClick={() => {
                    fetchWorklog();
                    setShowWorkLog(!showWorkLog);
                  }}
                  className="btn btn-success col-lg-2 mb-1"
                >
                  Worklog
                </div>
                <div
                  type="button"
                  title="logged issues"
                  onClick={() => {
                    fetchSprintIssues();
                    setShowTable(!showTable);
                  }}
                  className="btn btn-success dropdown-toggle col-lg-2 mb-1"
                >
                  <span className="visually-hidden">Toggle Dropdown</span>
                </div>
              </div>
              {showWorkLog && (
                <div className="mt-1 w-100" style={{ position: "initial" }}>
                  {worklogLoading ? (
                    <div className="container-fluid justify-content text-center">
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                    </div>
                  ) : (
                    workLog[selectedUser.id] && (
                      <WorklogTableComponent
                        worklog={workLog[selectedUser.id][selectedSprint.name]}
                      />
                    )
                  )}
                </div>
              )}

              {showTable && (
                <div className="mt-1 w-100" style={{ position: "initial" }}>
                  {issuesLoading ? (
                    <div className="container-fluid justify-content text-center">
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                    </div>
                  ) : (
                    sprintIssuesWTimeLogged[selectedUser.id] && (
                      <IssuesTable
                        sprintIssuesWTimeLogged={
                          sprintIssuesWTimeLogged[selectedUser.id][
                            selectedSprint.name
                          ]
                        }
                      />
                    )
                  )}
                </div>
              )}
              {showPopUp && (
                <Popup text="This action will delete all the users and sprints data. To refresh a particular users record press sprint name button." deleteAction={clearAllIssues} showPopUp={setShowPopUp} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  sprintIssuesWTimeLogged: getSprintIssuesWTimeLogged,
  selectedUser: selectedUser,
  selectedSprint: selectSelectedSprint,
  issuesLoading: getIssuesLoading,
  worklogLoading: getWorkLogLoading,
  workLog: getWorkLogByUser,
});

const mapDispatchToProps = (dispatch) => ({
  setIssuesLoading: (val) => dispatch(setIssuesLoadingAction(val)),
  setWorkLogLoadingAction: (val) => dispatch(setWorkLogLoading(val)),
  fetchSprintIssues: () => dispatch(fetchSprintIssuesByUsernameAction()),
  clearSprintIssuesForUser: (user) => dispatch(clearSprintIssuesForUser(user)),
  clearAllIssues: () => dispatch(clearAllSprintIssuesForAllUser()),
  fetchWorklog: () => dispatch(fetchWorkLogDayWise()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SprintsTable);
