import React from "react";
import SprintView from "../../components/sprints view/sprints-view.component";
import { Link } from "react-router-dom";
import ScrollToTop from "../../components/scrolltotop/scrolltop.component";

const HomePage = () => {
  return (
    <div className="site-wrapper">
      <header>
        <Link className="site-logo" to="/">
          Sprint_Tracker
        </Link>
      </header>
      <SprintView />
      <footer>&#169; 2023 Sprint_Tracker</footer>
      <ScrollToTop />
    </div>
  );
};

export default HomePage;
