import sprintsApi from "../../apis/sprints/sprints.api";
import {
  setAlertTypeAndMessage,
  toggleAlertVisibility,
} from "../alerts/alerts.actions";
import { userActionTypes } from "./user.types";

export const addToUsersAction = (user) => ({
  type: userActionTypes.ADD_USER,
  payload: user,
});

export const setSelectedUserAction = (user) => ({
  type: userActionTypes.SET_SELECTED_USER,
  payload: user,
});

export const fetchingUserHasErrors = (error) => ({
  type: userActionTypes.SET_ERRORS,
  payload: error,
});

export const setHasErrorsAction = (hasError) => ({
  type: userActionTypes.SET_HAS_ERRORS,
  payload: hasError,
});

export const clearAllUsers = () => ({
  type: userActionTypes.CLEAR_ALL_USERS,
  payload: null
})

export const fetchUserDetails = (userName) => {
  return async (dispatch) => {
    dispatch(
      setAlertTypeAndMessage({
        type: "info",
        message: "Please wait while we fetch user details",
      })
    );
    dispatch(toggleAlertVisibility(true));
    dispatch(setHasErrorsAction(true));

    await sprintsApi
      .get(`/user-details/${userName}`)
      .then((res) => {
        dispatch(addToUsersAction(res.data.data));
        dispatch(setSelectedUserAction(res.data.data));
        dispatch(setHasErrorsAction(false));
        dispatch(
          setAlertTypeAndMessage({
            type: "success",
            message: res.data.message,
          })
          );
          dispatch(toggleAlertVisibility(true));
      })
      .catch((error) => {
        dispatch(fetchingUserHasErrors(error));
        dispatch(
          setAlertTypeAndMessage({
            type: "error",
            message: error.response?.data.message,
          })
        );
        dispatch(toggleAlertVisibility(true));
      });
  };
};
