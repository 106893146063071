import {createSelector} from 'reselect'

const selectUser = (state) => state.user

export const selectAllUsers = createSelector(
    [selectUser],
    (user) => user.allUsers
)

export const selectHasErrors = createSelector(
    [selectUser],
    (user) => user.hasError
)

export const selectedUser = createSelector(
    [selectUser],
    (user) => user.selectedUser
)