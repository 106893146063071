import { addToAllSprints } from "./sprints.selectors";
import { sprintActionTypes } from "./sprints.types";

const INITAL_STATE = {
  selectedSprint: {},
  allSprints: [],
  issuesLoading: true,
  hasError: true,
  error: null,
  sprintIssuesWTimeLogged: {},
  worklog: {},
  worklogLoading: true,
};

const sprintReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case sprintActionTypes.ADD_SPRINT:
      return {
        ...state,
        allSprints: addToAllSprints(action.payload, state.allSprints),
      };
    case sprintActionTypes.SET_SELECTED_SPRINT:
      return {
        ...state,
        selectedSprint: action.payload,
      };
    case sprintActionTypes.SET_ISSUES_LOADING:
      return {
        ...state,
        issuesLoading: action.payload,
      };
    case sprintActionTypes.SET_ISSUES_W_TIME_LOGGED: {
      const { user, data } = action.payload;
      return {
        ...state,
        sprintIssuesWTimeLogged: state.sprintIssuesWTimeLogged
          ? {
              ...state.sprintIssuesWTimeLogged,
              [user.id]: {
                ...state.sprintIssuesWTimeLogged[user.id],
                [state.selectedSprint.name]: data,
              },
            }
          : {
              [user.id]: {
                [state.selectedSprint.name]: data,
              },
            },
      };
    }
    case sprintActionTypes.CLEAR_ISSUES_FOR_USER: {
      const user = action.payload;
      return {
        ...state,
        sprintIssuesWTimeLogged:
          state.sprintIssuesWTimeLogged &&
          state.sprintIssuesWTimeLogged[user.id]
            ? {
                ...state.sprintIssuesWTimeLogged,
                [user.id]: {
                  ...state.sprintIssuesWTimeLogged[user.id],
                  [state.selectedSprint.name]: [],
                },
              }
            : state.sprintIssuesWTimeLogged,
        issuesLoading: true,
      };
    }
    case sprintActionTypes.CLEAR_WORKLOG_FOR_SELECTED_USER: {
      const user = action.payload;
      return {
        ...state,
        worklog:
          state.worklog && state.worklog[user.id]
            ? {
                ...state.worklog,
                [user.id]: {
                  ...state.worklog[user.id],
                  [state.selectedSprint.name]: [],
                },
              }
            : state.worklog,
        worklogLoading: true,
      };
    }
    case sprintActionTypes.SET_ERRORS:
      return {
        ...state,
        error: action.payload,
        hasError: true,
      };
    case sprintActionTypes.SET_HAS_ERRORS:
      return {
        ...state,
        hasError: action.payload,
      };
    case sprintActionTypes.CLEAR_ALL_ISSUES_FOR_ALL_USER:
      return {
        ...state,
        sprintIssuesWTimeLogged: {},
        issuesLoading: true,
      };
    case sprintActionTypes.CLEAR_ALL_WORKLOG_FOR_ALL_USER:
      return {
        ...state,
        worklog: {},
        worklogLoading: true,
      };
    case sprintActionTypes.SET_WORKLOG:
      const { user, data } = action.payload;
      return {
        ...state,
        worklog: state.worklog
          ? {
              ...state.worklog,
              [user.id]: {
                ...state.worklog[user.id],
                [state.selectedSprint.name]: data,
              },
            }
          : {
              [user.id]: {
                [state.selectedSprint.name]: data,
              },
            },
      };
    case sprintActionTypes.SET_WORKLOG_LOADING:
      return {
        ...state,
        worklogLoading: action.payload,
      };
    default:
      return state;
  }
};

export default sprintReducer;
