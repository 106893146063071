import React, { useState } from "react";
import { createStructuredSelector } from "reselect";
import { selectSelectedSprint } from "../../redux/sprints/sprints.selectors";
import { connect } from "react-redux";
import { convertSecondsInDHMformat } from "../../utils/utils";
import { selectedUser } from "../../redux/user/user.selectors";
import { FaInfo, FaInfoCircle } from "react-icons/fa";
import HoverInfoBox from "./hoverinfo.component";
const moment = require("moment");

const WorkLogTable = ({ worklog, user, sprint }) => {
  const headers = worklog
    ? worklog.map((log) => {
        return { date: log.date, day: log.day };
      })
    : [];
  const weekend = (head) => {
    return (
      head.day.toLowerCase() === "saturday" ||
      head.day.toLowerCase() === "sunday"
    );
  };
  const stickyCol = {
    position: "sticky",
    left: 0,
    zIndex: 1,
    padding: "10px",
  };

  const totalTime = convertSecondsInDHMformat(
    worklog.reduce((acc, log) => (acc += log.timeLogged), 0)
  );

  return (
    <div className="container-fluid issues-table">
      <p className="text-center fs-4 fw-bolder">
        Total worklog by {user.displayName} for sprint {sprint.name}
      </p>
      <div className={false && `worklog-table-container`}>
        <table
          className={`${
            false ? "worklog-table" : ""
          } table-responsive table-bordered border-info dropdown-item w-100 table table-light table-striped table-sm`}
        >
          <thead>
            <tr>
              <th scope="col" className="table-success" style={stickyCol}>
                Total
              </th>
              {headers.length > 0 &&
                headers.map((head) => (
                  <th
                    key={head.date}
                    scope="col"
                    className={`${weekend(head) && "table-info"}`}
                  >
                    {moment(head.date).format("Do MMM YY")}
                    <br />
                    {moment(head.day, "dddd").format("dddd")}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="table-success" style={stickyCol}>
                {totalTime}
              </th>
              {worklog &&
                worklog.map((log) => {
                  const time = convertSecondsInDHMformat(log.timeLogged);
                  return (
                    <td
                      key={log.id}
                      className={`${weekend(log) && "table-info"}`}
                      style={{ padding: "10px" }}
                    >
                      <div className="date-info-container overflow-visible">
                        {time ? time : "-"}
                        {time ? <HoverInfoBox issues={log.issuesDayWise ? log.issuesDayWise : []} /> : null}
                      </div>
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  sprint: selectSelectedSprint,
  user: selectedUser,
});

export default connect(mapStateToProps, null)(WorkLogTable);
