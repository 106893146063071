import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  fetchUserDetails,
  setSelectedUserAction,
} from "../../redux/user/user.actions";
import {
  selectAllUsers,
  selectHasErrors,
  selectedUser,
} from "../../redux/user/user.selectors";
import {
  setAlertTypeAndMessage,
  toggleAlertVisibility,
} from "../../redux/alerts/alerts.actions";
import {
  selectAllSprints,
  selectSelectedSprint,
  sprintAdditionHasErrors,
} from "../../redux/sprints/sprints.selectors";
import {
  fetchActiveSprintsAction,
  fetchSprintDetails,
  setIssuesLoadingAction,
  setSelectedSprintAction,
  setWorkLogLoading,
} from "../../redux/sprints/sprints.actions";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Form, InputGroup, ListGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDebounce } from "../../utils/utils";
import sprintsApi from "../../apis/sprints/sprints.api";

const SprintSelect = ({
  allUsers,
  setUserDetails,
  setAlert,
  toggleVisible,
  hasErrors,
  setSelectedUser,
  selectedUser,
  allSprints,
  addSprint,
  sprintHasError,
  setSelectedSprint,
  setIssuesLoading,
  getActiveSprint,
  selectedSprint,
  setWorkLogLoadingAction,
}) => {
  const [userPreferences, setUserPreferences] = useState({
    sprintToBeAdded: "",
    userToBeAdded: "",
  });
  const [sprintSelect, setSprintSelect] = useState({});
  const [user, setUser] = useState({});
  const [suggUsers, setSuggUsers] = useState([]);
  const [showList, setShowList] = useState(true);
  const [noUsersFound, setNoUsersFound] = useState(false);
  const ref = useRef(null);

  const sprintFormik = useFormik({
    initialValues: {
      sprintToBeAdded: userPreferences.sprintToBeAdded,
    },
    validationSchema: Yup.object({
      sprintToBeAdded: Yup.string()
        .min(4, "Sprint should be more than 4 digits")
        .test(
          "is-uppercase",
          "Sprint must be uppercase",
          (val) => val === val.toUpperCase()
        )
        .required("Sprint cannot be empty"),
    }),
    onSubmit: (values) => {
      const handleSprintAddition = () => {
        const existingSprint = allSprints.find(
          (item) => item.name === userPreferences.sprintToBeAdded.trim()
        );
        if (existingSprint) {
          setAlert({
            type: "info",
            message: `sprint ${existingSprint.name} already exist in the selection`,
          });
          toggleVisible(true);
          return;
        }
        addSprint(userPreferences.sprintToBeAdded.trim());
      };

      if (values) handleSprintAddition();
    },
  });

  useEffect(() => {
    if (!sprintHasError) {
      setUserPreferences((prevState) => ({
        ...prevState,
        sprintToBeAdded: "",
      }));
      sprintFormik.resetForm();
    }
  }, [sprintHasError]);

  const userFormik = useFormik({
    initialValues: {
      userToBeAdded: userPreferences.userToBeAdded,
    },
    validationSchema: Yup.object({
      userToBeAdded: Yup.string()
        .min(4, "User must be more than 3 characters")
        .required("User cannot be empty"),
    }),
    onSubmit: (values) => {
      const handleUserAddition = () => {
        const existingUser = allUsers.find(
          (user) => user.displayName === userPreferences.userToBeAdded.trim()
        );

        if (existingUser) {
          setAlert({
            type: "info",
            message: `${existingUser.displayName} already exist in the selection`,
          });
          toggleVisible(true);
          return;
        }
        setUserDetails(userPreferences.userToBeAdded.trim());
      };

      handleUserAddition();
    },
  });

  useEffect(() => {
    if (!hasErrors) {
      setUserPreferences((prevState) => ({ ...prevState, userToBeAdded: "" }));
    }
    userFormik.resetForm();
  }, [hasErrors]);

  useEffect(() => {
    getActiveSprint();
  }, []);

  useEffect(() => {
    setUser(selectedUser);
    setSprintSelect(selectedSprint);
  }, [selectedUser]);

  const searchQuery = async () => {
    setNoUsersFound(false);
    if (
      userPreferences.userToBeAdded !== "" &&
      !userFormik.errors.userToBeAdded
    ) {
      setSuggUsers([]);
      setShowList(true);
      const response = await sprintsApi.get(
        `user-details/search/${userPreferences.userToBeAdded}`
      );
      const data = await response.data;
      if (data.data.length === 0) setNoUsersFound(true);
      setSuggUsers(data.data);
    } else setSuggUsers([]);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setUserPreferences({ ...userPreferences, [e.target.name]: e.target.value });
  };

  const debouncedAPI = useDebounce(searchQuery, 200);
  const handleUserChange = (e) => {
    e.preventDefault();
    setUserPreferences({ ...userPreferences, [e.target.name]: e.target.value });
    debouncedAPI();
  };

  const handleDropdown = (e) => {
    e.preventDefault();
    const sprint = allSprints.find((item) => item.name === e.target.value);
    setSelectedSprint(sprint);
    setSprintSelect(sprint);
    setIssuesLoading(true);
    setWorkLogLoadingAction(true);
  };

  const handleUsersDropdown = (e) => {
    e.preventDefault();
    const user = allUsers.find((item) => item.displayName === e.target.value);
    setSelectedUser(user);
    setUser(user);
  };

  useEffect(() => {
    function closeLists(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowList(false);
        setNoUsersFound(false);
      }
    }

    document.addEventListener("click", closeLists);

    return () => {
      document.removeEventListener("click", closeLists);
    };
  });

  return (
    <div className="container-fluid text-center">
      <div className="row">
        <div className="col-lg-2 col-7 mb-1">
          <InputGroup hasValidation>
            <FloatingLabel controlId="sprintTextField" label="provide sprint">
              <Form.Control
                as="input"
                placeholder="provide sprint"
                name="sprintToBeAdded"
                value={userPreferences.sprintToBeAdded}
                onChange={(e) => {
                  handleChange(e);
                  sprintFormik.handleChange(e);
                }}
                onBlur={sprintFormik.handleBlur}
                isInvalid={
                  sprintFormik.touched.sprintToBeAdded &&
                  sprintFormik.errors.sprintToBeAdded
                }
              />
              <Form.Control.Feedback type="invalid">
                {sprintFormik.touched.sprintToBeAdded &&
                  sprintFormik.errors.sprintToBeAdded}
              </Form.Control.Feedback>
            </FloatingLabel>
          </InputGroup>
        </div>
        <div className="col-lg-2 col-5 mb-1">
          <Button
            type="button"
            onClick={sprintFormik.handleSubmit}
            className="btn btn-primary w-100 h-100"
          >
            Add Sprint
          </Button>
        </div>

        <div className="col-lg-2 col-7 mb-1">
          <InputGroup>
            <FloatingLabel
              controlId="userTextField"
              label="provide display name"
            >
              <Form.Control
                as="input"
                placeholder="provide display name"
                name="userToBeAdded"
                value={userPreferences.userToBeAdded}
                onChange={(e) => {
                  handleUserChange(e);
                  userFormik.handleChange(e);
                }}
                onBlur={userFormik.handleBlur}
                isInvalid={
                  userFormik.touched.userToBeAdded &&
                  userFormik.errors.userToBeAdded
                }
              />
              <Form.Control.Feedback type="invalid">
                {userFormik.touched.userToBeAdded &&
                  userFormik.errors.userToBeAdded}
              </Form.Control.Feedback>
              <div style={{ position: "relative" }}>
                {suggUsers.length > 0 && showList && (
                  <ListGroup className="user-list">
                    {suggUsers.map((user) => {
                      return (
                        <ListGroup.Item
                          ref={ref}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setUserPreferences({
                              ...userPreferences,
                              userToBeAdded: user.displayName,
                            });
                            userFormik.errors.userToBeAdded = false;
                            setShowList(false);
                          }}
                          key={user.id}
                        >
                          {user.displayName}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                )}
                {noUsersFound && (
                  <ListGroup ref={ref} className="user-list">
                    <ListGroup.Item variant="dark">
                      No User Found
                    </ListGroup.Item>
                  </ListGroup>
                )}
              </div>
            </FloatingLabel>
          </InputGroup>
        </div>
        <div className="col-lg-2 col-5 mb-1">
          <Button
            type="button"
            onClick={userFormik.handleSubmit}
            className="btn btn-primary w-100 h-100"
          >
            Add Users
          </Button>
        </div>

        <div className="col-lg-2 mb-1">
          <Form.Select
            size="lg"
            className="form-select h-100"
            value={user?.displayName}
            name="sprintSelect"
            onChange={handleUsersDropdown}
            required
            aria-label="Default select example"
          >
            {/* <option disabled defaultValue={true}>
              select user
            </option> */}
            {allUsers &&
              allUsers.map((item) => {
                return (
                  <option
                    key={item.id}
                    data-id={item.id}
                    value={item.displayName}
                  >
                    {item.displayName}
                  </option>
                );
              })}
          </Form.Select>
        </div>

        <div className="col-lg-2">
          <Form.Select
            size="lg"
            className="form-select h-100"
            value={sprintSelect.name}
            name="sprintSelect"
            onChange={handleDropdown}
            required
            aria-label="Default select example"
          >
            {/* <option disabled>
              select sprint
            </option> */}
            {allSprints &&
              allSprints.map((item) => {
                return (
                  <option key={item.id} data-id={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </Form.Select>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  allUsers: selectAllUsers,
  hasErrors: selectHasErrors,
  selectedUser: selectedUser,
  allSprints: selectAllSprints,
  sprintHasError: sprintAdditionHasErrors,
  selectedSprint: selectSelectedSprint,
});

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: (userName) => {
    dispatch(fetchUserDetails(userName));
  },
  setAlert: (msg) => {
    dispatch(setAlertTypeAndMessage(msg));
  },
  toggleVisible: (val) => {
    dispatch(toggleAlertVisibility(val));
  },
  setSelectedUser: (user) => {
    dispatch(setSelectedUserAction(user));
  },
  addSprint: (sprintName) => {
    dispatch(fetchSprintDetails(sprintName));
  },
  setSelectedSprint: (sprintName) => {
    dispatch(setSelectedSprintAction(sprintName));
  },
  setIssuesLoading: (val) => {
    dispatch(setIssuesLoadingAction(val));
  },
  getActiveSprint: () => {
    dispatch(fetchActiveSprintsAction());
  },
  setWorkLogLoadingAction: (val) => {
    dispatch(setWorkLogLoading(val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SprintSelect);
