
export const sprintActionTypes = {
    SET_SELECTED_SPRINT:'SET_SELECTED_SPRINT',
    ADD_SPRINT:'ADD_SPRINT',
    SET_ERRORS: 'SET_ERRORS',
    SET_HAS_ERRORS: 'SET_HAS_ERRORS',
    SET_ISSUES_LOADING: 'SET_ISSUES_LOADING',
    SET_ISSUES_W_TIME_LOGGED:'SET_ISSUES_W_TIME_LOGGED',
    CLEAR_ISSUES_FOR_USER: 'CLEAR_ISSUES_FOR_USER',
    CLEAR_ALL_ISSUES_FOR_ALL_USER  : 'CLEAR_ALL_ISSUES_FOR_ALL_USER',
    SET_WORKLOG_LOADING: 'SET_WORKLOG_LOADING',
    SET_WORKLOG: 'SET_WORKLOG',
    CLEAR_WORKLOG_FOR_SELECTED_USER  : 'CLEAR_WORKLOG_FOR_SELECTED_USER',
    CLEAR_ALL_WORKLOG_FOR_ALL_USER  : 'CLEAR_ALL_WORKLOG_FOR_ALL_USER',

}