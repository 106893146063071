import React from "react";
import SprintSelect from "../sprints addition/sprints-dropdown.component";
import SprintsTable from "../sprints table/sprints-table.component";
import Alerts from "../alerts/alerts.component";

const SprintView = () => {
  return (
    <div className="container-fluid">
      <Alerts />
      <SprintSelect />
      <SprintsTable />
    </div>
  );
};

export default SprintView;
