import logger from 'redux-logger'
import {legacy_createStore, applyMiddleware} from 'redux'
import rootReducer from './rootReducer'
import persistStore from 'redux-persist/es/persistStore'
import {thunk} from 'redux-thunk'

const middlewares = [thunk]
if(process.env.REACT_APP_ENV === 'dev')
    middlewares.push(logger)
export const store = legacy_createStore(
    rootReducer,
    applyMiddleware(...middlewares)
)

export const persistor = persistStore(store)
// persistor.purge()
const expObj = {
    store,
    persistor
}

export default expObj;
