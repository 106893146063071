import React, { useEffect, useState } from "react";
import { handleScrollToTop } from "../../utils/utils";

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    function toggleVisible() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      )
        setVisible(true);
      else setVisible(false);
    }

    document.addEventListener("scroll", toggleVisible);

    return () => {
      document.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <div className="container">
      <button
        id="back-to-top"
        className={`btn btn-light btn-lg back-to-top ${
          visible ? "back-to-top-visible" : ""
        }`}
        onClick={handleScrollToTop}
      >
        <i className="fas fa-chevron-up"></i>
      </button>
    </div>
  );
};

export default ScrollToTop;
