import { useMemo, useRef } from "react";
import { useEffect } from "react";
import {debounce} from "lodash/function"

export const convertSecondsInDHMformat = (seconds) => {
  if (seconds === null) return seconds;

  let days = Math.floor(seconds / (8 * 3600));
  let hours = Math.floor((seconds % (8 * 3600)) / 3600);
  let mins = Math.floor((seconds % 3600) / 60);

  const format = [
    days > 0 ? days + "d" : "",
    hours > 0 ? hours + "h" : "",
    mins > 0 ? mins + "m" : "",
  ].join("");

  return format;
};

export const useDebounce = (callback, wait) => {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, wait);
  }, [wait]);

  return debouncedCallback;
};


export const handleScrollToTop = () => {
  let start = window.startX
  let end = 0
  let change = end - start
  let startTime = null
  let duration = 500
  
  function animateScroll(currentTime){
    if(startTime === null) startTime = currentTime
    let timeElapsed = currentTime - startTime
    let progress = Math.min( timeElapsed / duration , duration)
    window.scrollTo(0, start + change * progress)

    if(timeElapsed < duration && start > 50){
      requestAnimationFrame(animateScroll)
    }
  }

  requestAnimationFrame(animateScroll)
}