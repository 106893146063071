import { alertActionTypes } from "./alerts.types";

export const setAlertTypeAndMessage = (typeAndMsgObj) => ({
  type: alertActionTypes.SET_ALERT,
  payload: typeAndMsgObj
})

export const toggleAlertVisibility = (val) => ({
  type: alertActionTypes.TOGGLE_VISIBLE,
  payload: val
})