import { userActionTypes } from "./user.types";

const INITAL_STATE = {
  selectedUser: null,
  allUsers: [],
  error: null,
  hasError: true,
  userAlreadyExist: false,
};

const userReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case userActionTypes.ADD_USER: {
      return {
        ...state,
        allUsers: [...state.allUsers, action.payload],
        hasError: false,
      };
    }
    case userActionTypes.SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser:
          state.selectedUser ? action.payload : state.allUsers[0]
      };
    }
    case userActionTypes.SET_ERRORS: {
      return {
        ...state,
        error: action.payload,
        hasError: true,
      };
    }
    case userActionTypes.SET_HAS_ERRORS: {
      return {
        ...state,
        hasError: action.payload,
      };
    }
    case userActionTypes.CLEAR_ALL_USERS:{
      return{
        ...state,
        allUsers: [],
        selectedUser: null
      }
    }
    default:
      return state;
  }
};

export default userReducer;
