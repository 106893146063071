import {createSelector} from 'reselect'

const selectAlert = state => state.alert

export const selectAlertTypeAndMessage = createSelector(
    [selectAlert],
    (alert) => alert.alerts
)

export const selectVisible = createSelector(
    [selectAlert],
    (alert) => alert.visible
)