import React, { useState } from "react";
import { FaInfo } from "react-icons/fa";

const HoverInfoBox = ({ issues }) => {
  const [showBox, setShowBox] = useState(false);
  return (
    <div
      className="info-container"
      onMouseEnter={() => {
        setShowBox(true);
      }}
      onMouseLeave={() => {
        setShowBox(false);
      }}
    >
      <FaInfo className="info-icon" />
      {showBox && (
        <div className="info-box">
          <ul>
            {issues.length
              ? issues.map((issue, index) => {
                  return (
                    <li key={issue.issueId + " " + index}>
                      <b>{issue.key}</b> - {issue.timeSpent}
                    </li>
                  );
                })
              : "None"}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HoverInfoBox;
