import React, { useEffect, useMemo, useState } from "react";
import BuildDefect from "../../assets/icons8-bug-16.png";
import Bug from "../../assets/icons8-traffic-cone-16.png";
import Task from "../../assets/icons8-tick-16.png";
import Story from "../../assets/icons8-bookmark-16.png";
import DeploymentTask from "../../assets/rocket-svgrepo-com.svg";
import Epic from "../../assets/crown-svgrepo-com.svg";
import { Col, Form, Pagination, Row } from "react-bootstrap";
import { convertSecondsInDHMformat } from "../../utils/utils";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import FilterDropDown from "../filter-dropdowns/filterDropDown.components";

const renderIcon = (fields) => {
  let imgSrc = null;
  switch (fields.type) {
    case "Bug":
      imgSrc = Bug;
      break;
    case "Build Defect":
      imgSrc = BuildDefect;
      break;
    case "Task":
      imgSrc = Task;
      break;
    case "Story":
      imgSrc = Story;
      break;
    case "Deployment Task":
      imgSrc = DeploymentTask;
      break;
    case "Epic":
      imgSrc = Epic;
      break;
    default:
      imgSrc = fields.iconUrl;
      break;
  }

  return <img src={imgSrc} alt={fields.type} title={fields.type} />;
};

const IssuesTable = ({ sprintIssuesWTimeLogged }) => {
  const [activePage, setActivePage] = useState(1);
  const [show, setShow] = useState("10");
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  useEffect(() => {
    setTotalPages(
      Math.ceil(
        sprintIssuesWTimeLogged.length /
          (isNaN(show) ? sprintIssuesWTimeLogged.length : show)
      )
    );
  }, [show, sprintIssuesWTimeLogged]);

  useEffect(() => {
    setActivePage((prevState) => {
      return isNaN(show)
        ? 1
        : Math.min(prevState > 0 ? prevState : 1, totalPages);
    });
  }, [totalPages, show]);

  const headers = [
    "S.No.",
    "Type",
    "Key",
    "Status",
    "Summary",
    "Total Estimate",
    "Time Logged",
  ];

  const totalTimeLogged = () => {
    return convertSecondsInDHMformat(
      sprintIssuesWTimeLogged.reduce((total, issue) => {
        total += issue.fields.timeLogged;
        return total;
      }, 0)
    );
  };

  const handlePageChange = (number) => {
    setActivePage(number);
  };

  const requestSort = (key) => {
    key = key.toLowerCase();
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc")
      direction = "desc";
    setSortConfig({ key, direction });
  };

  const sortedData = useMemo(() => {
    let sortableData = [...sprintIssuesWTimeLogged];
    if (sortConfig.key) {
      //timeLogged
      let key =
        sortConfig.key === "time logged" ? "timeLogged" : sortConfig.key;
      sortableData.sort((a, b) => {
        if (a.fields[key] < b.fields[key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a.fields[key] > b.fields[key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    return sortableData.filter(
      (issue) =>
        (selectedStatus.length === 0 ||
          selectedStatus.includes(issue.fields.status)) &&
        (selectedType.length === 0 || selectedType.includes(issue.fields.type))
    );
  }, [sprintIssuesWTimeLogged, sortConfig, selectedStatus, selectedType]);

  useEffect(() => {
    setTotalPages(
      Math.ceil(sortedData.length / (isNaN(show) ? sortedData.length : show))
    );
  }, [show, sortedData]);

  const onSelectStatus = (selectedList, selectedItem) => {
    setSelectedStatus([...selectedList]);
  };

  const onRemoveStatus = (selectedList, selectedItem) => {
    setSelectedStatus([...selectedList]);
  };

  const onSelectType = (selectedList, selectedItem) => {
    setSelectedType([...selectedList]);
  };

  const onRemoveType = (selectedList, selectedItem) => {
    setSelectedType([...selectedList]);
  };

  const sortedStatusItems = useMemo(() => {
    onRemoveStatus([], "");
    setActivePage(1);
    let sortableData = Array.from(
      new Set(sprintIssuesWTimeLogged.map((issue) => issue.fields.status))
    );
    sortableData.sort();
    return sortableData;
  }, [sprintIssuesWTimeLogged]);

  const sortedTypeItems = useMemo(() => {
    onRemoveType([], "");
    setActivePage(1);
    let sortableData = Array.from(
      new Set(sprintIssuesWTimeLogged.map((issue) => issue.fields.type))
    );
    sortableData.sort();
    return sortableData;
  }, [sprintIssuesWTimeLogged]);

  return (
    <div className="container-fluid issues-table">
      {sprintIssuesWTimeLogged && (
        <p className="text-center fs-4 fw-bolder">
          Total issues worked on: {sprintIssuesWTimeLogged.length} and total
          time logged: {totalTimeLogged()}
        </p>
      )}
      <div className="table-container table-responsive">
        <table className="table-responsive table-bordered border-info dropdown-item d-block w-100 table table-light table-striped table-lg">
          <thead>
            <tr>
              {headers.map((item) => {
                if (item === "Status" || item === "Time Logged")
                  return (
                    <th
                      key={item}
                      style={{ cursor: "pointer", verticalAlign: "top" }}
                      onClick={() => requestSort(item)}
                    >
                      <>
                        {item}
                        {sortConfig.key === item.toLowerCase() ? (
                          sortConfig.direction === "asc" ? (
                            <TiArrowSortedUp />
                          ) : (
                            <TiArrowSortedDown />
                          )
                        ) : (
                          <TiArrowUnsorted />
                        )}
                      </>
                      {item === "Status" && (
                        <FilterDropDown
                          filterItems={sortedStatusItems}
                          placeholder={item}
                          onSelect={onSelectStatus}
                          onRemove={onRemoveStatus}
                          selectedValues={selectedStatus}
                        />
                      )}
                    </th>
                  );
                if (item === "Type") {
                  return (
                    <th style={{ verticalAlign: "top" }} key={item}>
                      {item}
                      <FilterDropDown
                        filterItems={sortedTypeItems}
                        placeholder={item}
                        onSelect={onSelectType}
                        onRemove={onRemoveType}
                        selectedValues={selectedType}
                      />
                    </th>
                  );
                }
                return (
                  <th style={{ verticalAlign: "top" }} key={item}>
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {sprintIssuesWTimeLogged &&
              sortedData
                .slice(
                  isNaN(show) ? 0 : (activePage - 1) * show,
                  isNaN(show) ? sortedData.length : activePage * show
                )
                .map((issue, index) => {
                  return (
                    <tr key={issue.id}>
                      <th scope="row">
                        {index +
                          1 +
                          (activePage - 1) * (show === "All" ? 1 : show)}
                      </th>
                      <td>
                        {renderIcon(issue.fields)} <b>[{issue.fields.type}]</b>
                      </td>
                      <td style={{ cursor: "pointer", color: "blue" }}>
                        {!issue.fields.timeLogged ? (
                          <span title="Not Logged">
                            <svg
                              width="30px"
                              height="30px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5Z"
                                fill="#ff0049"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span title="Logged">
                            <svg
                              width="30px"
                              height="30px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5Z"
                                fill="#00ff85"
                              />
                            </svg>
                          </span>
                        )}
                        <a
                          href={issue.fields.jiralink}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          style={{ textDecoration: "none" }}
                        >
                          {issue.key}
                        </a>
                      </td>
                      <td>{issue.fields.status}</td>
                      <td className="w-25">
                        {issue.fields.summary.length > 50
                          ? issue.fields.summary.substr(0, 50) + "..."
                          : issue.fields.summary}
                      </td>
                      <td>
                        {convertSecondsInDHMformat(
                          issue.fields.originalEstimate
                        ) ?? "-"}
                      </td>
                      <td>
                        {convertSecondsInDHMformat(issue.fields.timeLogged) ||
                          "-"}
                      </td>
                    </tr>
                  );
                })}
            <tr>
              <th scope="row">Total</th>
              <td colSpan={5}></td>
              <th scope="row">
                {sprintIssuesWTimeLogged && totalTimeLogged()}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row>
          <Col xs={"auto"}>
            <Form.Select
              size="md"
              value={show}
              onChange={(e) => {
                setShow(e.target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={"All"}>All</option>
            </Form.Select>
          </Col>
          <Col>
            <Pagination>
              <Pagination.First
                onClick={() => {
                  setActivePage(1);
                }}
                disabled={activePage === 1}
              />
              <Pagination.Prev
                onClick={() => {
                  activePage !== 1 && handlePageChange(activePage - 1);
                }}
                disabled={activePage === 1}
              />
              {Array.from(
                {
                  length: totalPages,
                },
                (_, i) => {
                  ++i;
                  return (
                    <Pagination.Item
                      key={i}
                      onClick={() => handlePageChange(i)}
                      active={i === activePage}
                    >
                      {i}
                    </Pagination.Item>
                  );
                }
              )
                .slice(
                  0 + Math.max(activePage - 5, 0),
                  5 + Math.max(activePage - 5, 0)
                )
                .map((item, index) => {
                  return item;
                })}
              <Pagination.Next
                onClick={() => {
                  activePage !== totalPages && handlePageChange(activePage + 1);
                }}
                disabled={activePage === totalPages}
              />
              <Pagination.Last
                onClick={() => setActivePage(totalPages)}
                disabled={activePage === totalPages}
              />
            </Pagination>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default IssuesTable;
