import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import userReducer from "./user/user.reducer";
import alertsReducer from "./alerts/alerts.reducer";
import sprintReducer from "./sprints/sprints.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "sprints"],
};

const rootReducer = combineReducers({
  user: userReducer,
  alert: alertsReducer,
  sprints: sprintReducer,
});

export default persistReducer(persistConfig, rootReducer);
