import React from "react";
import { Multiselect } from "multiselect-react-dropdown";

const FilterDropDown = ({ filterItems, placeholder, onSelect, onRemove, selectedValues }) => {
  return (
    <div onClick={(e) => {e.stopPropagation()}} style={{width: '100%'}}>
      <Multiselect
        options={filterItems}
        placeholder={placeholder}
        isObject={false}
        emptyRecordMsg="No Options Available"
        hidePlaceholder={true}
        style={{
            chips : {
                display : 'flex',
                justifyContent : 'center',
                textAlign : 'center',
            },
            searchBox : {
                width : '100%',
            },
            multiselectContainer:{
                width: '100%',
            }
        }}
        selectedValues={selectedValues}
        onSelect={onSelect}
        onRemove={onRemove}
      />
    </div>
  );
};

export default FilterDropDown;
