import React, { useEffect, useRef } from "react";

const Popup = ({ deleteAction, showPopUp, text }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    modalRef.current.focus();
    function handleBlockingScreen(e) {
      if (e.key === "Tab" || e.key === "Enter") {
        e.preventDefault();
      }
    }

    document.addEventListener("keydown", handleBlockingScreen);

    return () => {
      document.removeEventListener("keydown", handleBlockingScreen);
    };
  }, []);

  return (
    <div className="overlay">
      <div className="form-title-container" ref={modalRef}>
        <div className="text">{text}</div>
        <div className="buttons-container">
          <button
            title="Delete"
            onClick={() => {
              deleteAction();
              showPopUp(false);
            }}
          >
            Delete
          </button>
          <button title="Cancel" onClick={() => showPopUp(false)}>
            X
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
