import React, { useEffect } from "react";
import { connect } from "react-redux";
import { toggleAlertVisibility } from "../../redux/alerts/alerts.actions";
import { createStructuredSelector } from "reselect";
import {
  selectAlertTypeAndMessage,
  selectVisible,
} from "../../redux/alerts/alerts.selectors";

const Info = ({ message }) => {
  return (
    <div className="alert alert-info" role="alert">
      {message}
    </div>
  );
};

const Success = ({ message }) => {
  return (
    <div className="alert alert-success" role="alert">
      {message}
    </div>
  );
};

const Danger = ({ message }) => {
  return (
    <div className="alert alert-danger" role="alert">
      {message}
    </div>
  );
};

const Alerts = ({ alert, visible, toggleVisibility }) => {
  const { type, message } = alert;

  function handleAlertTimeout() {
    if (visible) {
      setTimeout(() => {
        toggleVisibility(false);
      }, 2500);
    }
  }

  useEffect(() => {
    handleAlertTimeout();
  }, [alert,visible,toggleVisibility]);

  const renderAlerts = (type, message) => {
    switch (type.toLowerCase()) {
      case "success":
        return <Success message={message} />;
      case "error":
        return <Danger message={message} />;
      case "info":
        return <Info message={message} />;
      default:
        break;
    }
  };

  return <div>{visible ? renderAlerts(type, message) : null}</div>;
};

const mapStateToProps = createStructuredSelector({
  alert: selectAlertTypeAndMessage,
  visible: selectVisible,
});

const mapDispatchToProps = (dispatch) => ({
  toggleVisibility: (val) => dispatch(toggleAlertVisibility(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
