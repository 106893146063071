import { createSelector } from "reselect";

const selectSprints = (state) => state.sprints;

export const selectAllSprints = createSelector(
  [selectSprints],
  (sprints) => sprints.allSprints
);

export const sprintAdditionHasErrors = createSelector(
  [selectSprints],
  (sprints) => sprints.hasError
);

export const selectSelectedSprint = createSelector(
  [selectSprints],
  (sprints) => sprints.selectedSprint
);

export const getSprintIssuesWTimeLogged = createSelector(
  [selectSprints],
  (sprints) => sprints.sprintIssuesWTimeLogged
);

export const getIssuesLoading = createSelector(
  [selectSprints],
  (sprints) => sprints.issuesLoading
);

export const getWorkLogLoading = createSelector(
  [selectSprints],
  (sprints) => sprints.worklogLoading
);

export const getWorkLogByUser = createSelector(
  [selectSprints],
  (sprints) => sprints.worklog
);

export const addToAllSprints = (sprint, allSprints) => {
  const existingSprint = allSprints.find((item) => item.name === sprint.name);

  if (existingSprint) return allSprints;

  return [...allSprints, sprint];
};
